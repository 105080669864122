<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ $t("label.Add Supplier") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-5 mb-4 text-center">
                          <div class="mb-3">
                            <img
                              :src="
                                detail.avatar && detail.avatar != null
                                  ? detail.avatar
                                  : '/images/profile.png'
                              "
                              class="rounded-circle border border-light"
                              style="border-width: 3px !important"
                              alt="image thumb"
                              id="preview"
                              width="130"
                              height="130"
                            />
                          </div>
                          <button
                            type="button"
                            class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                            for="pickFile"
                          >
                            {{ $t("label.Change photo") }}
                            <input
                              type="file"
                              class="btn upload-img"
                              id="pickFile"
                              @change="selectedFile($event)"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  v-model="detail.name"
                                  class="form-control filled"
                                  rules="required:name"
                                  :validateOnInput="true"
                                />
                                <label for="fname">{{ $t("label.Company Name") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="detail.email"
                                  class="form-control filled"
                                  rules="email"
                                  :validateOnInput="true"
                                />
                                <label for="email">{{ $t("label.Email") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <!-- <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="address"
                                  name="address"
                                  v-model="detail.address"
                                  class="form-control filled"
                                  rules="required:address"
                                  :validateOnInput="true"
                                />
                                <label for="address">Address</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="address" class="validation-msg" />
                            </div> -->

                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <vue-google-autocomplete
                                  ref="address"
                                  id="map"
                                  classname="form-control filled"
                                  :placeholder="$t('label.Type Here')"
                                  v-model="detail.address"
                                  v-on:placechanged="getAddressData"
                                  types=""
                                >
                                </vue-google-autocomplete>
                                <label for="city">{{ $t("label.Address") }}</label>
                                <i class="bx bx-map icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="city" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="phone">{{ $t("label.Phone") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="detail.phone_number"
                                  class="form-control filled"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                  ><PhoneCode
                                    id="mobile"
                                    placeholder="Mobile Number"
                                    v-model:countryCode="detail.country_code"
                                    v-model="detail.phone_number"
                                    v-model:dialCode="detail.dial_code"
                                    v-bind="field"
                                  />
                                </Field>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="com_registration_no"
                                  name="com_registration_no"
                                  v-model="detail.com_registration_no"
                                  class="form-control filled"
                                  :validateOnInput="true"
                                />
                                <label for="com_registration_no">{{
                                  $t("label.Commercial Registration No")
                                }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage
                                name="com_registration_no"
                                class="validation-msg"
                              />
                            </div>
                            <!-- <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="area"
                                  name="area"
                                  v-model="detail.operating_area"
                                  class="form-control filled"
                                  rules="required:area"
                                  :validateOnInput="true"
                                />
                                <label for="area">Operating Area</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="area" class="validation-msg" />
                            </div> -->
                            <div class="col-md-12 d-flex flex-row" v-if="addMore == '1'">
                              <div class="floating-form-group with-icon">
                                <vue-google-autocomplete
                                  ref="operating_area"
                                  id="operating_area"
                                  classname="form-control filled"
                                  :placeholder="$t('label.select address')"
                                  v-model="detail.operating_area"
                                  v-on:placechanged="getOperatingAddressData"
                                  types=""
                                >
                                </vue-google-autocomplete>
                                <label for="operating_area">{{
                                  $t("label.Operating Area")
                                }}</label>
                                <i class="bx bx-map icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <button
                                v-on:click="addToOperatingAreaList()"
                                type="button"
                                class="btn"
                                style="
                                  padding-top: 11px;
                                  border-radius: 0px 5px 5px 0px !important;
                                "
                              >
                                {{ $t("label.Add") }}
                              </button>
                              <ErrorMessage name="city" class="validation-msg" />
                            </div>
                            <div
                              class="col-md-12 mx-3"
                              v-show="operating_area.length > 0"
                            >
                              <div class="row">
                                <div
                                  class="col-lg-12 col-md-12 d-flex justify-content-between"
                                >
                                  <h5 class="">{{ $t("label.Operating Area") }}:</h5>
                                  <a
                                    v-on:click="showAddMore()"
                                    class="text-right"
                                    style="cursor: pointer"
                                  >
                                    {{ $t("label.Add More") }}
                                  </a>
                                </div>
                              </div>
                              <div class="row">
                                <br />

                                <table
                                  class="table table-style-1 table-responsive"
                                  id="sorting_tbl"
                                >
                                  <thead>
                                    <tr>
                                      <th>{{ $t("label.S_No") }}</th>
                                      <th>{{ $t("label.Work Address") }}</th>
                                      <th>{{ $t("label.Action") }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(data, index) in operating_area"
                                      :key="index"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>{{ data?.area }}</td>
                                      <td>
                                        <button
                                          class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                          v-on:click="showAddressPopup(index)"
                                          title="Delete"
                                        >
                                          <i class="bx bxs-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-md-12" v-if="!this.id">
                              <div class="floating-form-group">
                                <Field
                                  :type="passwordFieldType"
                                  id="password"
                                  name="password"
                                  v-model="detail.password"
                                  class="form-control filled"
                                  rules="required:password"
                                  :validateOnInput="true"
                                />
                                <label for="password">{{ $t("label.Password") }}</label>
                                <i class="bx bx-lock icon"></i>
                                <span
                                  class="input-group-text bg-white"
                                  id="basic-addon2"
                                  style="border-radius: 0px 8px 8px 0px"
                                >
                                  <a class="psw-icon" v-on:click="switchVisibility"
                                    ><img
                                      :src="image"
                                      width="20"
                                      style="margin-bottom: 10px" /></a
                                ></span>
                              </div>
                              <ErrorMessage name="password" class="validation-msg" />
                            </div>
                            <div class="col-md-12" v-if="!this.id">
                              <div class="floating-form-group">
                                <Field
                                  :type="newPasswordFieldType"
                                  id="confirm_password"
                                  name="confirm_password"
                                  v-model="detail.password_confirm"
                                  class="form-control filled"
                                  rules="required:confirm password|confirmed:@password"
                                  :validateOnInput="true"
                                />
                                <label for="password">{{
                                  $t("label.Confirm Password")
                                }}</label>
                                <i class="bx bx-lock icon"></i>
                                <span
                                  class="input-group-text bg-white"
                                  id="basic-addon2"
                                  style="border-radius: 0px 8px 8px 0px"
                                >
                                  <a class="psw-icon" v-on:click="switchVisibilityNew"
                                    ><img
                                      :src="imageNew"
                                      width="20"
                                      style="margin-bottom: 10px" /></a
                                ></span>
                              </div>
                              <ErrorMessage
                                name="confirm_password"
                                class="validation-msg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-3">
                        <button
                          type="button"
                          class="btn btn-brand-1 mb-3 mr-2 px-5"
                          v-on:click="$router.go(-1)"
                        >
                          {{ $t("label.Cancel") }}
                        </button>
                        <button class="btn btn-brand-1 mb-3 px-5" id="save-button">
                          {{ $t("label.Save") }}
                        </button>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "AddSupplier",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueGoogleAutocomplete,
    PhoneCode,
  },
  data() {
    return {
      addMore: "1",
      id: this.$route.params.id,
      file: undefined,
      detail: {
        dial_code: "+966",
        country_code: "sa",
        name: "",
        email: "",
        address: "",
        status: "ACTIVE",
      },
      operating_area: [],
      latitude: "",
      city: "",
      longitude: "",
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      area: "",
      latitudes: "",
      longitudes: "",
    };
  },
  mounted() {
    if (this.id) {
      this.addMore = "0";
    }
  },
  methods: {
    showAddMore() {
      this.addMore = "1";
    },
    addToOperatingAreaList() {
      var area = $("#operating_area").val();
      if (area.length <= 0) {
        this.$notify({
          type: "error",
          text: "Please enter operating area",
        });

        return;
      }
      this.operating_area.push({
        area: this.area,
        latitude: this.latitudes,
        longitude: this.longitudes,
      });
      console.log("operating_area list updated", this.operating_area);
      $("#operating_area").val("");
      this.addMore = "0";
    },
    getAddressData: function (addressData) {
      this.detail.address = addressData.locality;
    },
    getOperatingAddressData: function (addressData, placeResultData) {
      this.area = placeResultData.formatted_address;
      this.latitudes = addressData.latitude;
      this.longitudes = addressData.longitude;

      console.log("address list updated", this.operating_area);
    },
    showAddressPopup(id) {
      this.operating_area.splice(id, 1);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    update() {
      if (!(this.operating_area.length > 0)) {
        this.$notify({
          type: "error",
          text: "Please select At-least one  operating area",
        });
      }
      this.detail.operating_area = this.operating_area;
      console.log("detail", this.detail);
      this.$api
        .postAPI({
          _action: "supplier",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.uploadImage(res.id);
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id) {
      if (!this.file) {
        this.$router.go(-1);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "supplier/" + id + "/image",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.detail.avatar = res.path;
          //this.$store.commit("setUser", this.detail);
          this.$router.push("/manage-supplier");
        });
    },
  },
};
</script>

